import "./App.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Userlog } from "./userlogs";
import { Home } from "./Home";
import { Visitors } from "./Visitors";
import { Layout } from "./Layout";
import { Chat, MakeMessageHtml } from "./Chat";
import io from "socket.io-client";
import { useEffect } from "react";
function App() {
  const socket = io(process.env.REACT_APP_SOCKET_URL, {
    Headers: {
      "ngrok-skip-browser-warning": "1",
    },
    extraHeaders: {
      "ngrok-skip-browser-warning": "1",
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1",
      },
    },
  });
  useEffect(() => {
    socket.on("connect", () => {
      console.log("Admin connected to server");
    });

    socket.on("disconnect", () => {
      console.log("Admin disconnected from server");
    });
    socket.on("message", (data) => {
      MakeMessageHtml(data);
    });
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/userlogs" element={<Userlog socket={socket}/>} />
          <Route path="/visitors" element={<Visitors />} />
          <Route path="/chat" element={<Chat socket={socket} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
