import { Link, Outlet } from "react-router-dom";

export function Layout() {
  return (
    <div className="App">
      <header className="App-header">
        <nav>
          <Link to="/userlogs" className="color">
            Userlog
          </Link>

          <Link to="/visitors" className="color">
            Visitors
          </Link>
        </nav>
      </header>
      <main>
        <Outlet />
      </main>
    </div>
  );
}
