import { useEffect, useState } from "react";
import "./userlogs.css";
import { Link } from "react-router-dom";
import { queries } from "@testing-library/react";
import axios from "axios";

export function Userlog({ socket }) {
  document.title = "Userlogs";
  
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    document.querySelectorAll('header nav a')[0].classList.toggle('active', true);
    document.querySelectorAll('header nav a')[1].classList.toggle('active', false);
    fetch(
      `https://api.amantes30.com/portfolio/userlogs?key=${process.env.REACT_APP_KEY}`
    ).then((res) => {
      res.blob().then((blob) => {
      blob.text().then((text) => {
        //console.log(JSON.parse(text));
        setLogs(JSON.parse(text));
      });
      });
    });
  }, []);
  function handleDelete(e) {
    e.preventDefault();

    const roomId = e.target.getAttribute("data");
    axios.post(`${process.env.REACT_APP_API_URL}/delete/?roomId=${roomId}`).then((res) => {
      console.log(res);
    });
    //socket.emit("delete-user", roomId);
    e.target.parentElement.parentElement.remove();
  }

  return (
    <>
      <table>
        <thead>
          <tr>
            <td>Username</td>
            <td>Time</td>
            <td>Room ID</td>
          </tr>
        </thead>
        <tbody>
          {logs.map((log, index) => (
            <tr key={index}>
              <td>{log.email}</td>
              <td>{log.time}</td>
              <td>
                <Link to={`/chat?roomId=${log.roomId}&key=${process.env.REACT_APP_KEY}`} className="join-btn">
                  {log.roomId}
                </Link>
              </td>

              <td>
                <svg
                  data={log.roomId}
                  onClick={handleDelete}
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="32"
                  fill="red"
                  className="bi bi-trash delete-btn"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"></path>
                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"></path>
                </svg>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
