import { useEffect, useState } from "react";
import "./visitors.css";

export function Visitors() {
  document.title = "Visitors";

  const KEY = "TcX8NzUajaHgn8udvsMlbG5F7AuWRQPeztUkhuxUEtY";
  const [logs, setLogs] = useState([]);
  const params = {};
  


  useEffect(() => {
    document.querySelectorAll('header nav a')[1].classList.toggle('active', true);
    document.querySelectorAll('header nav a')[0].classList.toggle('active', false);
    fetch(`https://api.amantes30.com/portfolio/visitors?key=${KEY}`, {}).then(
      (res) => {
        res.blob().then((blob) => {
          blob.text().then((text) => {
            console.log(JSON.parse(text));
            setLogs(JSON.parse(text));
          });
        });
      }
    );
    
  }, []);
  return (
    <>
    <h2>{logs.length} Visitors</h2>
      <table>
        <thead>
          <tr>
            <td>IP</td>

            <td>Time</td>
          </tr>
        </thead>
        <tbody>
          {logs.map((user, index) => (
            <tr key={index} className="room-btn">
              <td className="gd_text">
                <a href={`https://tools.keycdn.com/geo?host=${user.ip}`}>
                  {user.ip}
                </a>
              </td>

              <td className="gd_text">{user.time}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
