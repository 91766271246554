import { useEffect, useState } from "react";
import "./chat.css";

export function Chat({ socket }) {
  const roomId = new URLSearchParams(window.location.search).get("roomId");
  const key = new URLSearchParams(window.location.search).get("key");

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    document
      .querySelectorAll("header nav a")[1]
      .classList.toggle("active", false);
    document
      .querySelectorAll("header nav a")[0]
      .classList.toggle("active", false);
    fetch(`${process.env.REACT_APP_API_URL}/history?roomId=${roomId}`)
      .then((res) => {
        setMessages([]);
        res.blob().then((blob) => {
          blob.text().then((text) => {
            const chatHistory = JSON.parse(text);
            //console.log(chatHistory);
            setMessages(chatHistory);
          });
        });
      })
      .catch((err) => {
        document.body.innerHTML = "<h1>Invalid URL</h1>";
      });
    socket.emit("admin-join-room", roomId);

    const input = document.getElementById("input");
    const iform = document.getElementById("form");

    iform.addEventListener("submit", function (event) {
      event.preventDefault();
      if (input.value) {
        socket.emit("message", {
          roomId: roomId,
          message: input.value,
          sender: "admin",
        });
      }
      event.target.reset();
    });
  }, []);
  return (
    <div id="chat-window">
      <ul id="messages">
        {messages.forEach((msg) => {
          MakeMessageHtml(msg);
        })}
      </ul>
      <form id="form">
        <input
          id="input"
          autoComplete="off"
          placeholder="Type your message..."
        />
        <button type="submit" id="send-btn">
          Send
        </button>
      </form>
    </div>
  );
}

export function MakeMessageHtml(data) {
  const messages = document.getElementById("messages");
  const li = document.createElement("li");
  const message = document.createElement("p");
  const sender = document.createElement("p");

  sender.classList.add("gd_text");

  if (data.sender === "admin") {
    message.style.color = "black";
    li.style.flexDirection = "row";
    sender.textContent = "Me : ";
  } else {
    message.style.color = "black";
    sender.textContent = " : V";
  }
  message.textContent = `${data.message}`;
  li.appendChild(sender);
  li.append(" : ");
  li.appendChild(message);
  messages.appendChild(li);
  messages.scrollTop = messages.scrollHeight;
}
